<template>
  <div class="credentials-modal-error">
    <el-dialog
      title="Atenção!"
      :visible.sync="loginError"
      width="50%"
      center
      style="padding: 0px;"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <vs-row vs-type="flex" vs-justify="center" vs-align="center"  style="padding: 20px;">
        <p class="error-message">Houve um erro ao fazer Login, por favor tente novamente mais tarde.</p>
      </vs-row>
      <p slot="footer" class="dialog-footer">
        <el-button type="primary" @click="redirectLogin()">Tentar novamente</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      loginError: false
    };
  },
  computed: {},
  methods: {
    redirectLogin(){
      this.$router.go(-1)
    },
    ...mapActions("auth", ["login", "generateToken", "loginSSO"]),

    async submitForm(code) {
      this.$vs.loading();
      let payload = {
        grant_type: "authorization_code",
        code: code
      };

      return await this.loginSSO(payload)
        .then(data => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.loginError = true;
        })
        .finally(() => {
          this.$vs.loading.close()
        });
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    let hasCompanyKey = urlParams.has("k");
    let hasCode = urlParams.has("c");
    let hasDNS = urlParams.has("d");

    let companyKey = urlParams.get("k");
    let code = urlParams.get("c");
    let dns = urlParams.get("d");

    //colocar condicional para verificar companyKey vinda do cassdoor com a salva no environment
    // Se não for igual, lançar erro ou redirect

    this.submitForm(code);
  },
  mounted() {
    this.$appInsights.trackPageView({
      name: "page-credentials-sso",
      url: window.location.href
    });
  }
};
</script>
<style scoped lang="scss">
.credentials-modal-error {
  ::v-deep .el-dialog {
    border-radius: 6px;
    max-width: 530px;
    min-width: 530px;
    padding: 32px;
  }
  ::v-deep .el-dialog__title {
    font-size: 2em;
  }
  ::v-deep .el-dialog__body {
    padding: 0px !important;
    margin: 0px;
  }
  ::v-deep div .el-button--primary {
    background-color: rgba(var(--vs-primary), 1) !important;
  }
  ::v-deep div .error-message {
    text-align: center;
    font-weight: bold;
    color: black;
    font-size: 20px;
  }
}
</style>
